import { render, staticRenderFns } from "./ContactsInfo.vue?vue&type=template&id=54ddb767&"
import script from "./ContactsInfo.vue?vue&type=script&lang=js&"
export * from "./ContactsInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports