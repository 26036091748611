import { render, staticRenderFns } from "./ItemNotifications.vue?vue&type=template&id=2de984b1&"
import script from "./ItemNotifications.vue?vue&type=script&lang=js&"
export * from "./ItemNotifications.vue?vue&type=script&lang=js&"
import style0 from "./ItemNotifications.vue?vue&type=style&index=0&id=2de984b1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../temp_divo-ui/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports